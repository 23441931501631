<template>
  <div class="scheme-contain">
    <div class="member-oprate" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">
      <a href="javascript:;" class="del-doc fr" @click="handleDel">删除</a>
    </div>
    <div class="CheckPrint-detail-contain">
      <Form :label-width="85">
        <Row>
          <Col :lg="{span: 10}" :md="{span: 12}">
            <div class="img-box" @click="handleView">
              <img :src="CheckPrint.image" class="img-body" title="点击查看大图" v-if="isImage(CheckPrint.image)">
              <img src="@/assets/img/placeholder.png" class="img-body" v-else>
            </div>
            <a :href="CheckPrint.image" download class="download-link">下载</a>
          </Col>
          <Col :lg="{span: 10, offset: 2}" :md="{span: 12}">
            <template v-if="power.indexOf('会员管理--编辑会员信息权限') > -1">
              <Form-item label="标题：">
                <Select placeholder="请选择" v-model="CheckPrint.type">
                  <Option v-for="item in picTypeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
              </Form-item>
              <Form-item label="图片描述：">
                <Input type="textarea" v-model="CheckPrint.describe" :maxlength="200" :autosize="{minRows: 8, maxRows: 8}" placeholder="请输入..." @on-change="handleLen"></Input>
                <p class="text-info">注：最多只能输入200个字</p>
              </Form-item>
            </template>
            <template v-else>
              <Form-item label="标题：">
                <Select disabled placeholder="请选择" v-model="CheckPrint.type">
                  <Option v-for="item in picTypeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
              </Form-item>
              <Form-item label="图片描述：">
                <Input disabled type="textarea" v-model="CheckPrint.describe" :maxlength="200" :autosize="{minRows: 8, maxRows: 8}" placeholder="请输入..." @on-change="handleLen"></Input>
                <p class="text-info">注：最多只能输入200个字</p>
              </Form-item>
            </template>
            <Form-item label="留言查看：">
              <div class="message-contain">
                <div class="message-content" v-for="(message, index) in CheckMessage" :key="index">
                  <div class="message-name">
                    <span class="pr15">{{ message.word_user }}</span>
                    <span>{{ message.time }}</span>
                  </div>
                  <div class="message-list">
                    <p>{{ message.content }}</p>
                  </div>
                </div>
              </div>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col :lg="{span: 22}" :md="{span: 22}">
            <Form-item label="我要留言：">
              <Input type="textarea" v-model="commitMessage" :autosize="{minRows: 5, maxRows: 10}"></Input>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
    <!-- 点击查看大图Modal -->
    <imageView :visible="visible" :src="ImgSrc" @on-close="visible=false"></imageView>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import imageView from 'components/imageView/imageView';
export default {
	data() {
		return {
			picTypeList: [],
			commitMessage: '',
			CheckMessage: [{ word_user: '', content: '', time: '' }],
			CheckPrint: {
				member: '',
				type: '',
				check_date: '',
				image: '',
				describe: '',
				create_time: '',
			},
			power: [],
			visible: false,
			ImgSrc: '',
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},

		picId() {
			return this.$route.params.pic_id;
		},

		userInfo() {
			return this.$store.getters.userInfo;
		},
	},

	components: { imageView },

	created() {
		this.power = this.userInfo.power;
		MemberService.listPicType().then((data) => {
			this.picTypeList = data;
		});

		this.CheckPrint.type = this.picId;

		MemberService.detailPicCheck({ id: this.picId }).then((data) => {
			this.CheckPrint.type = data.type_id;
			this.CheckPrint.describe = data.describe;
			this.CheckPrint.image = data.photo;
			if (data.message_list) {
				this.CheckMessage = data.message_list;
			}
		});
	},

	methods: {
		handleBack() {
			this.$router.go(-1);
		},

		handleSave() {
			var req = {
				id: this.picId,
				type_id: this.CheckPrint.type,
				content: this.commitMessage,
				describe: this.CheckPrint.describe,
			};
			if (this.CheckPrint.describe) {
				MemberService.updatePicCheck(req).then(() => {
					// this.$Message.success('数据更新成功')
					this.$router.go(-1);
					// this.$router.push({name: 'CheckPrintDetail', params: {'pic_id': this.picId}})
				});
			} else {
				this.$Message.warning('图片描述不能为空！');
			}
		},

		handleDel() {
			this.$Modal.confirm({
				title: '提醒',
				content: '确定删除该数据吗',
				onOk: () => {
					this.handleConfirmDel();
				},
			});
		},

		handleConfirmDel() {
			MemberService.delPicCheck({ id: this.picId }).then(() => {
				// this.$Message.success('数据删除成功')
				this.$router.go(-1);
			});
		},
		handleView() {
			if (this.isImage(this.CheckPrint.image)) {
				this.visible = true;
				this.ImgSrc = this.CheckPrint.image;
			}
		},
		handleLen() {
			if (this.CheckPrint.describe.length > 199) {
				this.$Message.warning('最多只能输入200个字');
			}
		},
		isImage(path) {
			return path.match(/\.(png|jpe?g|gif|svg)$/);
		},
	},
};
</script>
<style lang="css" scoped>
.CheckPrint-detail-contain {
  padding: 30px;
}
.CheckPrint-detail-contain .img-box {
  border: 1px solid #eee;
  cursor: pointer;
  height: 530px;
}
.message-contain {
  border: 1px solid #eee;
  max-width: 100%;
  height: 262px;
  overflow-y: auto;
  padding: 10px;
}
.message-contain .message-name {
  color: #999;
  font-size: 14px;
}
.message-contain .message-content {
  margin-bottom: 15px;
}
.message-contain .message-list p {
  word-wrap: break-word;
  line-height: 25px;
}
.message-contain .pr15 {padding-right: 15px;}
.text-info {
  color: #999;
}
.img-body {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.download-link {
  color: #fdad00;
  text-align: right;
  display: block;
  padding: 5px 0;
}
</style>
